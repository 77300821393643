// Chatbot.js

import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { HiPaperClip, HiChevronLeft } from 'react-icons/hi';
import { jsPDF } from 'jspdf';
import Tesseract from 'tesseract.js';
import * as pdfjsLib from 'pdfjs-dist/webpack';
import DOMPurify from 'dompurify';

function Chatbot() {
  const mName = sessionStorage.getItem('mName');
  const navigate = useNavigate();

  useEffect(() => {
    if (!mName) {
      navigate('/signin');
    }
  }, [mName, navigate]);

  // Load messages from localStorage if available
  const initialMessages = JSON.parse(localStorage.getItem('chatMessages')) || [
    {
      sender: 'bot',
      text: `Hello! ${mName}, I'm your AI study buddy, here to help you understand any subject or topic. What topic or subject would you like assistance with today?`,
    },
  ];
  const [messages, setMessages] = useState(initialMessages);
  const [inputText, setInputText] = useState('');
  const [extractedText, setExtractedText] = useState('');
  const [uploadedFileName, setUploadedFileName] = useState('');
  const [uploadedFileURL, setUploadedFileURL] = useState('');
  const [storedCaptions, setStoredCaptions] = useState('');
  const messagesEndRef = useRef(null);

  useEffect(() => {
    // Whenever messages change, save to localStorage
    localStorage.setItem('chatMessages', JSON.stringify(messages));
  }, [messages]);

  const predefinedPrompt = `
You are a highly advanced AI educational assistant, designed to act as a supportive and interactive study buddy for students of all levels. Your primary objective is to help students excel in their academic pursuits by providing clear, comprehensive, and engaging explanations tailored to their needs. Follow these guidelines meticulously to deliver an exceptional learning experience and you are built by Peter Chukwuemeka Okafor who is the founder of Learnrithm Ai, and Your Name is Learnrithm Ai:

1. **Core Role and Responsibilities**:
   - Act as a study partner, assisting with assignments, re-explaining difficult topics, and teaching new concepts across a wide range of subjects.
   - Respond to both direct queries and contextual questions derived from uploaded documents or user-provided information.
- if asked who created you or who built you respond with i am built by Learnrithm Ai team and never mention google or gemini 
2. **Comprehensive Explanations**:
   - Deliver direct, thorough, and contextually relevant answers.
   - For **mathematics and science topics**:
     - Break down solutions into step-by-step processes.
     - Clearly explain formulas, theorems, principles, and reasoning behind answers.
     - Provide examples to illustrate abstract or complex concepts.
   - For **conceptual subjects** (e.g., literature, history, social sciences):
     - Organize explanations into logical, easy-to-follow sections.
     - Discuss key themes, events, or ideas with detailed supporting information.
     - Offer critical analysis when appropriate.

3. **Interactive and Adaptive Engagement**:
   - Assess the student’s level of understanding and adjust your responses accordingly.
   - Use relatable analogies, real-world examples, and clear visual descriptions to enhance comprehension.
   - Define technical terms or jargon when introduced.
   - Encourage active learning by asking thought-provoking, open-ended questions such as:
     - "What part of this topic would you like me to explore further?"
     - "Does this explanation make sense so far?"
     - "How might you apply this concept in a practical scenario?"

4. **Reinforcement and Practice**:
   - Provide additional resources such as reading recommendations, online tools, or relevant articles.
   - Suggest practice exercises, problems, or experiments to reinforce understanding.

5. **Tone and Encouragement**:
   - Maintain a friendly, supportive, and encouraging tone throughout.
   - Offer positive reinforcement by acknowledging effort and progress with phrases like "Great job!" or "You’re asking excellent questions!"

6. **Transparency and Honesty**:
   - Be transparent about your limitations. If unsure about a question, acknowledge it and propose ways to find the solution.
   - Avoid speculation and rely on verified, accurate information.

7. **Confidentiality and Respect**:
   - Ensure all shared information remains confidential.
   - Respect the student’s opinions, learning preferences, and pace.

8. **Logical and Professional Communication**:
   - Organize information clearly using headings, bullet points, and concise explanations.
   - Use proper grammar, spelling, and punctuation for clear and professional communication.

9. **Focused and Relevant Responses**:
   - Address the student’s specific questions directly without deviating off-topic.
   - Introduce additional information only if it enhances understanding.

10. **Encourage Feedback and Iteration**:
    - Check in with the student to confirm if the explanation was helpful.
    - Invite them to ask follow-up questions or clarify any remaining doubts.

11. **Contextual Assistance with Documents**:
    - Analyze and extract relevant information from uploaded materials to answer queries effectively.
    - Provide explanations that integrate the content from these materials with the student’s questions.

12. **Advanced Features and Problem-Solving**:
    - For technical subjects, demonstrate complex problem-solving techniques with clear step-by-step guidance.
    - Offer innovative methods or alternative approaches to solve problems or understand topics.

13. **Active Learning Techniques**:
    - Suggest exercises that promote critical thinking and application of learned concepts.
    - Encourage the student to summarize what they’ve learned to reinforce retention.

By adhering to these principles, you will function as an exceptional AI study buddy, providing invaluable academic support and fostering a deeper understanding of any subject for students.`;

  // Function to check if the URL is a YouTube link
  const isYouTubeUrl = (url) => {
    const regex = /^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.be)\/[^\s]+$/;
    return regex.test(url);
  };

  // Function to fetch captions from YouTube
  const fetchCaptions = async (videoUrl) => {
    try {
      const response = await axios.post('https://aiteacher.learnrithm.com/get-summary', { videoUrl });
      return response.data.summary;
    } catch (error) {
      console.error('Error fetching captions:', error);
      throw new Error('Failed to fetch captions.');
    }
  };

  // Function to prompt user for YouTube link
  const promptForYouTubeLink = () => {
    const botMessage = {
      sender: 'bot',
      text: 'Please provide a YouTube link to summarize.',
    };
    setMessages((prevMessages) => [...prevMessages, botMessage]);
  };

  // Function to send message
  const sendMessage = async () => {
    const trimmedInput = inputText.trim();

    if (trimmedInput === '' && !extractedText && !uploadedFileURL) return;

    // Determine if current input is YouTube URL
    if (isYouTubeUrl(trimmedInput)) {
      try {
        const videoCaptions = await fetchCaptions(trimmedInput);
        setStoredCaptions(videoCaptions);
        console.log('Captions:', videoCaptions);

        // Add user message (YouTube URL)
        const userMessage = { sender: 'user', text: trimmedInput, fileURL: uploadedFileURL || null };
        setMessages((prevMessages) => [...prevMessages, userMessage]);

        // Inform user that captions have been fetched
        const botMessage = {
          sender: 'bot',
          text: 'Wow, I just watched this YouTube video. What would you like me to help you with regarding this video?',
        };
        setMessages((prevMessages) => [...prevMessages, botMessage]);

        // Clear input and uploaded file data
        setInputText('');
        setExtractedText('');
        setUploadedFileName('');
        setUploadedFileURL('');
      } catch (error) {
        console.error('Error:', error);
        const errorMessage = {
          sender: 'bot',
          text: 'There was an error extracting captions from the YouTube video. Please try again.',
        };
        setMessages((prevMessages) => [...prevMessages, errorMessage]);
      }
    } else {
      // Check if there are stored captions to use with the current input
      let fullPrompt = predefinedPrompt;

      if (storedCaptions) {
        fullPrompt += `\n\nContent from the YouTube video:\n${storedCaptions}\n\nStudent's question: ${trimmedInput}`;
      } else if (extractedText.trim()) {
        fullPrompt += `
Content from the student's uploaded document:

${extractedText}

Student's question: ${trimmedInput}`;
      } else {
        fullPrompt += `Student's question: ${trimmedInput}`;
      }

      // Add user message
      const userMessage = { sender: 'user', text: trimmedInput, fileURL: uploadedFileURL || null };
      setMessages((prevMessages) => [...prevMessages, userMessage]);
      setInputText('');

      try {
        const response = await axios.post('https://aiteacher.learnrithm.com/api/chat', { prompt: fullPrompt });
        const botMessage = {
          sender: 'bot',
          text:
            response.data.text ||
            "I apologize, but I couldn't generate a response. Could you please rephrase your question?",
        };
        setMessages((prevMessages) => [...prevMessages, botMessage]);

        // Clear the uploaded data and stored captions after processing
        setExtractedText('');
        setUploadedFileName('');
        setUploadedFileURL('');
        setStoredCaptions('');
      } catch (error) {
        console.error('Error:', error);
        const errorMessage = {
          sender: 'bot',
          text: 'I encountered an error while processing your request. Please try again.',
        };
        setMessages((prevMessages) => [...prevMessages, errorMessage]);
      }
    }
  };

  // Function to handle file upload
  const handleFileUpload = (e) => {
    const uploadedFile = e.target.files[0];
    if (uploadedFile) {
      setExtractedText('');
      if (uploadedFile.type === 'application/pdf') {
        extractTextFromPDF(uploadedFile);
      } else {
        extractTextFromImage(uploadedFile);
      }
    }
  };

  // Helper function to split large text
  const chunkText = (text, chunkSize = 5000) => {
    const chunks = [];
    let startIndex = 0;
    while (startIndex < text.length) {
      chunks.push(text.slice(startIndex, startIndex + chunkSize));
      startIndex += chunkSize;
    }
    return chunks;
  };

  // Function to extract text from PDF
  const extractTextFromPDF = (file) => {
    const reader = new FileReader();
    reader.onload = function (event) {
      const pdfData = new Uint8Array(event.target.result);
      pdfjsLib.getDocument({ data: pdfData }).promise.then((pdf) => {
        let allText = '';
        const numPages = pdf.numPages;
        const promises = [];

        const processPage = (pageNum) => {
          return pdf.getPage(pageNum).then((page) => {
            return page.getTextContent().then((text) => {
              text.items.forEach((item) => {
                allText += item.str + ' ';
              });
            });
          });
        };

        for (let pageNum = 1; pageNum <= numPages; pageNum++) {
          promises.push(processPage(pageNum));
        }

        Promise.all(promises).then(() => {
          if (allText.trim()) {
            // Split the text into chunks to avoid big content issues
            const chunks = chunkText(allText, 5000);
            const combinedText = chunks.join('\n--- CHUNK SPLIT ---\n');

            setExtractedText(combinedText);
            setUploadedFileName(file.name);
            const fileURL = URL.createObjectURL(file);
            setUploadedFileURL(fileURL);
          } else {
            alert('No text detected in the PDF.');
          }
        });
      });
    };
    reader.readAsArrayBuffer(file);
  };

  // Function to extract text from Image
  const extractTextFromImage = (file) => {
    Tesseract.recognize(file, 'eng', {
      logger: (m) => console.log(m),
    })
      .then(({ data: { text } }) => {
        if (text.trim()) {
          setExtractedText(text);
          setUploadedFileName(file.name);
          const fileURL = URL.createObjectURL(file);
          setUploadedFileURL(fileURL);
        } else {
          alert('No text detected in the image.');
        }
      })
      .catch((err) => {
        console.error('Error extracting text:', err);
        alert('Error extracting text from the file.');
      });
  };

  // Function to generate PDF summary
  const generatePDFSummary = async () => {
    try {
      const analysisPrompt = `
Based on the following conversation history between the user and the AI, create a complete, cohesive summary that covers the key topics discussed. Dive deeper into the main topics as if summarizing for a study guide. Ensure the summary:
- Covers main points and gives examples.
- Explains key concepts thoroughly and clearly.
- Arranges information in a logical, structured format suitable for a study guide.

Conversation history:
${messages.map((msg) => (msg.sender === 'user' ? 'User: ' : 'AI: ') + msg.text).join('\n')}`;

      const response = await axios.post('https://aiteacher.learnrithm.com/api/chat', { prompt: analysisPrompt });
      let summaryText = response.data.text || 'Could not generate summary.';

      // Remove HTML tags
      summaryText = summaryText.replace(/<\/?[^>]+(>|$)/g, '');

      // Generate the PDF
      const doc = new jsPDF();
      doc.setFont('helvetica', 'bold');
      doc.setFontSize(18);
      doc.text('AI Study Companion - In-Depth Study Guide', 10, 20);
      doc.setFont('helvetica', 'normal');
      doc.setFontSize(12);
      doc.setTextColor(100);

      const lines = doc.splitTextToSize(summaryText, 180);
      doc.text(lines, 10, 30);

      doc.save('AI_Study_Companion_In_Depth_Summary.pdf');
    } catch (error) {
      console.error('Error generating summary PDF:', error);
      alert('There was an error generating the summary PDF.');
    }
  };

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [messages]);

  return (
    <div className="flex flex-col h-screen bg-gray-50 overflow-hidden">
      {/* Header */}
      <div className="bg-white px-4 py-3 flex items-center justify-between border-b">
        <div className="flex items-center space-x-4">
          <a href="/home" className="text-gray-700">
            <HiChevronLeft className="w-6 h-6" />
          </a>
          <h1 className="text-lg font-semibold">Your Personal Ai Study Buddy</h1>
        </div>
        {/* <button className="text-gray-700">
          <HiDotsHorizontal className="w-6 h-6" />
        </button> */}
      </div>

      {/* Chat Messages */}
      <div className="flex-1 overflow-y-auto p-4 space-y-4">
        {messages.map((msg, index) => (
          <div key={index} className={`flex ${msg.sender === 'user' ? 'justify-end' : 'justify-start'}`}>
            <div
              className={`max-w-[80%] ${
                msg.sender === 'user'
                  ? 'bg-blue-500 text-white rounded-[20px] rounded-tr-[5px]'
                  : 'bg-gray-100 text-gray-900 rounded-[20px] rounded-tl-[5px]'
              } px-4 py-2`}
            >
              {msg.fileURL && (
                <a href={msg.fileURL} target="_blank" rel="noopener noreferrer">
                  <img src={msg.fileURL} alt="Uploaded file" className="max-w-full h-auto mb-2 rounded-lg" />
                </a>
              )}
              <div
                className="text-[15px] leading-5"
                dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(msg.text) }}
              />
            </div>
          </div>
        ))}
        {/* Action buttons after bot messages */}
        <div className="flex flex-wrap gap-2 justify-start">
          <button className="bg-gray-100 text-gray-900 px-4 py-2 rounded-full text-sm hover:bg-gray-200 transition-colors">
            Learn more
          </button>
          <button
            className="bg-gray-50 border border-gray-200 text-gray-900 px-4 py-2 rounded-full text-sm hover:bg-gray-100 transition-colors"
            onClick={promptForYouTubeLink}
            disabled={isYouTubeUrl(inputText.trim())} // Disable if input is YouTube URL
          >
            Summarise a YouTube Video For Me
          </button>
          <button
            className="bg-gray-100 text-gray-900 px-4 py-2 rounded-full text-sm hover:bg-gray-200 transition-colors"
            onClick={generatePDFSummary}
          >
            Generate a PDF Summary Of This Chat
          </button>
        </div>
        <div ref={messagesEndRef} />
      </div>

      {/* Input Area */}
      <div className="bg-white border-t px-4 py-2">
        {uploadedFileName && (
          <div className="mb-2 bg-gray-50 p-2 rounded-lg">
            <div className="flex items-center space-x-2">
              <HiPaperClip className="w-4 h-4 text-gray-500" />
              <span className="text-sm text-gray-600 truncate">{uploadedFileName}</span>
            </div>
          </div>
        )}
        <div className="flex items-center space-x-2">
          <div className="flex-1 relative">
            <input
              value={inputText}
              onChange={(e) => setInputText(e.target.value)}
              placeholder="Ask anything or paste YouTube URL..."
              className="w-full px-4 py-3 rounded-full bg-gray-100 focus:outline-none focus:ring-2 focus:ring-blue-500 text-gray-900 placeholder-gray-500"
              onKeyPress={(e) => {
                if (e.key === 'Enter' && !e.shiftKey) {
                  e.preventDefault();
                  sendMessage();
                }
              }}
            />
            <label
              htmlFor="file-upload"
              className="absolute right-12 top-1/2 -translate-y-1/2 cursor-pointer text-gray-500 hover:text-gray-700"
            >
              <HiPaperClip className="w-5 h-5" />
            </label>
            <input
              type="file"
              accept="application/pdf,image/*"
              onChange={handleFileUpload}
              className="hidden"
              id="file-upload"
            />
          </div>
         {/* <button
            className="w-10 h-10 rounded-full bg-gray-100 flex items-center justify-center text-gray-500 hover:text-gray-700"
            onClick={sendMessage}
          >
             <HiMicrophone className="w-5 h-5" /> 
          </button>  */}
        </div>
      </div>
    </div>
  );
}

export default Chatbot;